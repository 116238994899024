import * as React from "react";
import { Privacy } from "../components/privacy";

const PrivacyPage = () => {
    return (
        <>
            <head>
                <title>Fidize - Privacy policy</title>
            </head>
            <Privacy />
        </>
    )
}

export default PrivacyPage
